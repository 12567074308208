import domReady from '@wordpress/dom-ready'
import $ from 'jquery'

domReady(function () {
	const slick = window.slick
	const sliders = $('.hero-slider-wrapper')
	// const sliderButtonsDiv = document.createElement('div')
	// sliderButtonsDiv.className = 'slider-buttons'
	// sliders[0].appendChild(sliderButtonsDiv)
	// const settings = JSON.parse(sliders.attr('data-settings'))
	$('<div>', {
		class: 'slider-buttons',
	}).appendTo('.wp-block-hegner-shop-hero-slider')

	sliders.slick({
		appendArrows: $('.slider-buttons')
	})
})
